var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { lg: "6" } },
            [
              _c("CTableWrapper", {
                attrs: { items: _vm.getShuffledUsersData() },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c("CIcon", { attrs: { name: "cil-grid" } }),
                        _vm._v(" Simple Table "),
                        _c("div", { staticClass: "card-header-actions" }, [
                          _c(
                            "a",
                            {
                              staticClass: "card-header-action",
                              attrs: {
                                href:
                                  "https://coreui.io/vue/docs/components/nav",
                                rel: "noreferrer noopener",
                                target: "_blank"
                              }
                            },
                            [
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("docs")
                              ])
                            ]
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { lg: "6" } },
            [
              _c("CTableWrapper", {
                attrs: {
                  items: _vm.getShuffledUsersData(),
                  striped: "",
                  caption: "Striped Table"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { lg: "6" } },
            [
              _c("CTableWrapper", {
                attrs: {
                  items: _vm.getShuffledUsersData(),
                  small: "",
                  caption: "Condensed Table"
                }
              })
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { lg: "6" } },
            [
              _c("CTableWrapper", {
                attrs: {
                  items: _vm.getShuffledUsersData(),
                  fixed: "",
                  bordered: "",
                  caption: "Bordered Table"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "12" } },
            [
              _c("CTableWrapper", {
                attrs: {
                  items: _vm.getShuffledUsersData(),
                  hover: "",
                  striped: "",
                  bordered: "",
                  small: "",
                  fixed: "",
                  caption: "Combined All Table"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "12" } },
            [
              _c("CTableWrapper", {
                attrs: {
                  items: _vm.getShuffledUsersData(),
                  hover: "",
                  striped: "",
                  bordered: "",
                  small: "",
                  fixed: "",
                  dark: "",
                  caption: "Combined All dark Table"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }