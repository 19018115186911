var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _vm._t("header", [
            _c("CIcon", { attrs: { name: "cil-grid" } }),
            _vm._v(" " + _vm._s(_vm.caption) + " ")
          ])
        ],
        2
      ),
      _c(
        "CCardBody",
        [
          _c("CDataTable", {
            attrs: {
              hover: _vm.hover,
              striped: _vm.striped,
              bordered: _vm.bordered,
              small: _vm.small,
              fixed: _vm.fixed,
              items: _vm.items,
              fields: _vm.fields,
              "items-per-page": _vm.small ? 10 : 5,
              dark: _vm.dark,
              pagination: ""
            },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      [
                        _c(
                          "CBadge",
                          { attrs: { color: _vm.getBadge(item.status) } },
                          [_vm._v(_vm._s(item.status))]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }